<template>
    <v-container class="pt-16">
        <v-row class="d-flex justify-center">
            <v-col cols="12" class="col-sm-12 col-xs-12 col-lg-8 col-xl-8">
                <v-card>
                    <v-card-title>Handelsbetingelser</v-card-title>
                    <v-card-text>
                        <p></p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Terms"
    }
</script>

<style scoped>

</style>